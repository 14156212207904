/**
 * Async method to obtain a uiFramework's ui adapter
 * @returns {Promise<*>}
 */


let getUiFrameworkAdapterAsync = async () => {
    let module =  await utilities.customImportWithAppSource(`assets/frameworks/${config.style.frameworks[0]}.js`);
    return module.default || module;
};

/**
 * Method to assign and lazy create $saffron on a Vue instance
 * @param instance
 */
let lazyAssignSaffron = (instance) => {
    // get or create saffron
   let  $saffron = {};
    /* causes vue warn in ssr
    let $saffron;
console.log('here');
    if (instance.$saffron) {
        $saffron = instance.$saffron;
    } else {
        $saffron = {};
    }

     */
//    let $saffron = instance.$saffron || {};

    // make sure it's assign to instance
    instance.$saffron = $saffron;

    // grab the $s namespace if desired
    if (config.useNamespaceS) {
        instance.$s = $saffron;
    }
};

/**
 * Method to add modal functionality to a $saffron (under the ui namespace)
 * @param uiAdapter
 * @param instance
 * @param $saffron
 */
let addModalsToSaffronUi = (uiAdapter, instance, $saffron) => {
    let isHtml = (arg) =>  /<\/?[a-z][\s\S]*>/i.test(arg);

    let getModalDefaultLanguageStrings = (type) => {
        let defaults = {
            okButton : 'core.ui.modal.okButtonText',
            cancelButton : 'core.ui.modal.cancelButtonText',
        };
        switch(type) {
            case 'prompt':
                defaults.title = 'core.ui.modal.promptTitle';
                break;
            case 'confirm':
                defaults.title = 'core.ui.modal.confirmTitle';
                break;

            case 'alert':
            default:
                defaults.title = 'core.ui.modal.alertTitle';
                break;
        }

        return defaults;
    };

    let parseModalArgs = (content, title, options, type = 'alert') => {
        let languageKeys = getModalDefaultLanguageStrings(type);

        // text translation
        if ( ! isHtml(content)) {
            content = instance.translate(content);
        }

        // title default
        if(typeof title !== 'string') {
            title = languageKeys.title
        }

        // title translation
        if ( ! isHtml(title)) {
            title = instance.translate(title);
        }

        // options default
        if (typeof options !== 'object' || options === null) {
            options = {};
        }

        // options labels integrity
        if (typeof options.labels !== 'object') {
            options.labels = {
                okButton : languageKeys.okButton,
                cancelButton : languageKeys.cancelButton,
            }
        }

        // translate labels
        Object.keys(options.labels).forEach( (key) => {
            options.labels[key] = instance.translate(options.labels[key]);
        });

        // rename labels
        options.labels['ok'] = options.labels['ok'] || options.labels['okButton'];
        options.labels['cancel'] = options.labels['cancel'] || options.labels['cancelButton'];

        // assign the title to the options, thats the API from UIkit
        options.title = options.title || title;

        return {finalContent:content, finalOptions: options}
    };

    // build proxy

    let customConfirm  = (content, title = null, options = {}) => {
        if (typeof document === 'undefined') {
            return new Promise(() => {});
        }

        let {finalContent, finalOptions} = parseModalArgs(content, title, options, 'confirm');
        return uiAdapter.modal.confirm(finalContent, finalOptions);
    };
    let customAlert    = (content, title = null, options = {}) => {
        if (typeof document === 'undefined') {
            return new Promise(() => {});
        }

        let {finalContent, finalOptions} = parseModalArgs(content,title, options, 'alert');
        return uiAdapter.modal.alert(finalContent, finalOptions)
    };
    let customPrompt   = (content, title = null, options = {}) => {
        if (typeof document === 'undefined') {
            return new Promise(() => {});
        }

        let {finalContent, finalOptions} = parseModalArgs(content,title, options, 'prompt');
        let value = options.value || '';
        return uiAdapter.modal.prompt(finalContent, value, finalOptions);
    };

    let safeModalAccessor = (prop) => {
        if (typeof document === 'undefined') {
            return new Promise(() => {});
        } else {
            return(uiAdapter.modal[prop]);
        }

    };

    let modalProxy = new Proxy(uiAdapter.modal, {


        get: (target, prop, receiver) => {
            switch(prop) {
                case 'alert':
                    return customAlert;
                    break;

                case 'confirm':
                    return customConfirm;
                    break;
                case 'prompt':
                    return customPrompt;
                    break;
                default:
                    return safeModalAccessor(prop);
                    break;
            }
        }
    });

    // modals
    $saffron.ui.modal = modalProxy;


};

let addNotificationToSaffronUi = (uiAdapter, instance, $saffron) => {
    let notification = (message, type, position, timeout) => {
        if (typeof document === 'undefined') {
            return $saffron;
        }

        let defaultTimeout      = 5000,
            defaultType         = 'default',
            defaultPosition     = 'top-center';

        if (typeof message === 'object') {
            timeout     = message.timeout   || defaultTimeout;
            type        = message.type      || defaultType;
            position    = message.position  || defaultPosition;
            message     = message.message   || '';
        }

        if (typeof type !== 'string') {
            type = defaultType;
        }

        if (typeof type !== 'string') {
            position = defaultPosition
        }

        if (typeof type !== 'number') {
            timeout = defaultTimeout;
        }

        // the use of "error" is intuitive for developers. so allow it and "translate it to uikit"
        if (type === 'error') {
            type = 'danger';
        }

        uiAdapter.notification({
                message: message,
                status: type,
                pos: position,
                timeout: timeout
            });

        return $saffron;
    };

    notification.closeAll = () => {
        if (typeof document === 'undefined') {
            return $saffron;
        }

        uiAdapter.notification.closeAll();
        return $saffron;
    };

    let clearAndNotify = (message, type, position, timeout) => {
        if (typeof document === 'undefined') {
            return $saffron;
        }

        notification.closeAll();
        return notification(message, type, position, timeout);

    };
    $saffron.ui.clearAndNotify = clearAndNotify;
    $saffron.ui.notification = notification;
};

/**
 * Method to ass a UI object to $saffron
 * @param uiAdapter
 * @param instance
 * @param $saffron
 */
let addUiToSaffron = (uiAdapter, instance, $saffron) => {
    // assign (create if needed) saffronUI object inside $saffron
    $saffron.ui = $saffron.ui || {};

    $saffron.ui.adapter = $saffron.ui.adapter || uiAdapter;
    addModalsToSaffronUi(uiAdapter, instance, $saffron);
    addNotificationToSaffronUi(uiAdapter, instance, $saffron);


    // todo: more stuff! yey!
};


/**
 * Method to add $saffron to instance. May also assign by reference into $s, if configured to do so
 * @param uiAdapter
 * @param instance
 */
let addSaffronToInstance = (uiAdapter, instance) => {
    lazyAssignSaffron(instance);
    addUiToSaffron(uiAdapter, instance, instance.$saffron);
};


export default new Promise(async (resolve) => {
    let uiAdapter = await getUiFrameworkAdapterAsync();

    resolve({
        install (app) {
            app.mixin({
                created () {
                    addSaffronToInstance(uiAdapter, this);
                }
            });
        },
    })
});


